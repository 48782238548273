// @import "~bootstrap/scss/bootstrap";
@import "Shadows.scss";
@import "loginAndRegisterPages.scss";
@import "mainPage.scss";
@import "wordsPage.scss";

.error-message {
    color: red;
}

.error-input {
    border: 1px solid red;
}

/*imports*/
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

/* variables */
:root {
    /* colors */
    --primary-color: #f44336;
    --primary-color-dark: color(#f44336 shade(12%));
    --primary-color-tint: color(#f44336 tint(70%));
    --secondary-color: #222;
    --secodnary-color-dark: color(#222 shade(12%));
    --secondary-color-tint: color(#222 tint(70%));

    /* width */
    --form-width: 23rem;
}

html {
    // color: #fff;
    // background-image: linear-gradient(45deg, #292729, #464345);
    // background-attachment: fixed;

    // font-family: 'Open Sans', sans-serif;
    font-family: "Roboto", sans-serif;
    // height: 100vh;

    body {
        margin: 0;
    }
}

.highlight {
    background-color: #f3ff85;
    color: #131313;
}
