.form {
    width: var(--form-width);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15% auto 0 auto;

    &_title {
        font-weight: bold;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: x-large;
    }

    &_inputs {
        width: var(--form-width);
        margin-bottom: 15px !important;
    }

    &_button {
        width: 100%;
    }

    &_other {
        &_login {
            width: 100%;
            margin-top: 8px;
            display: flex;
            justify-content: right;

            & a {
                text-decoration: none;
                display: flex;
                align-items: center;

                svg {
                    margin-right: 5px;
                }
            }
        }

        &_register {
            width: 100%;
            margin-top: 8px;
            display: flex;
            justify-content: left;

            & a {
                text-decoration: none;
                display: flex;
                align-items: center;

                svg {
                    margin-right: 5px;
                }
            }
        }
    }



    .logo {
        margin-bottom: 25px;
        font-size: 30px;
        font-weight: bold;
    }

}