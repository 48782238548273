.preloaderBody {
    z-index: 2000;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    overflow: hidden;

    .content {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
    }

    .loading {
        display: inline-block;
        position: relative;
        width: 128px;
        height: 128px;
    }
    .loading div {
        position: absolute;
        background: #000000;
        opacity: 1;
        border-radius: 50%;
        animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    .loading div:nth-child(2) {
        animation-delay: -0.7s;
    }

    @keyframes loading {
        0% {
            top: 56px;
            left: 56px;
            width: 0;
            height: 0;
            opacity: 1;
        }
        100% {
            top: -1px;
            left: -1px;
            width: 116px;
            height: 116px;
            opacity: 0;
        }
    }
}

.preloaderBody.updating {
    position: fixed !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.5) !important;
}
