.main-page-form-container {
    //variables
    --input-margin-bottom: 1.5rem;

    @media screen and (min-width: 600px) {
        --form-width-highth: 600px;

        form {
            width: var(--form-width-highth);

            .text-field {
                width: var(--form-width-highth);
                margin-bottom: 15px !important;
            }

            .button {
                width: var(--form-width-highth);
            }
        }
    }

    @media screen and (max-width: 600px) {
        --form-width-mobile: 100%;

        form {
            width: var(--form-width-mobile);

            .text-field {
                width: var(--form-width-mobile);
                margin-bottom: 15px !important;
            }

            .button {
                width: var(--form-width-mobile);
            }
        }
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15% auto 0 auto;

    &_title {
        font-weight: bold;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: x-large;
    }

    &_button {
        width: 100%;
    }

    &_other {
        &_login {
            width: 100%;
            margin-top: 8px;
            display: flex;
            justify-content: right;

            & a {
                text-decoration: none;
                display: flex;
                align-items: center;

                svg {
                    margin-right: 5px;
                }
            }
        }

        &_register {
            width: 100%;
            margin-top: 8px;
            display: flex;
            justify-content: left;

            & a {
                text-decoration: none;
                display: flex;
                align-items: center;

                svg {
                    margin-right: 5px;
                }
            }
        }
    }

    .title {
        margin-bottom: 25px;
        font-size: 30px;
        font-weight: bold;
    }
}
